<script>
import { app } from '@/main'
import { mapActions, mapGetters } from 'vuex'
import { required, requiredIf, minValue, url } from 'vuelidate/lib/validators'
import { formatSlug } from '@/support/utils/stringHelper'
import { debounce } from 'lodash'

const TRANSLATION = {
  description: {
    description: app.$t('global:form.solutions.description'),
    audience: app.$t('global:form.solutions.audience'),
    differentials: app.$t('global:form.solutions.differentials')
  }
}

const RICH_TEXT_EDITOR_TOOLBAR = [
  [
    'bold',
    'italic',
    'underline',
    'strike'
  ],
  [ 'clean' ],
  [ 'link' ]
]

const isUrlYoutubeOrVimeo = value => {
  // eslint-disable-next-line no-useless-escape
  return !value || /^(http:\/\/|https:\/\/)(vimeo\.com|youtu\.be|www\.youtube\.com)\/([\w\/]+)([\?].*)?$/.test(value)
}

const isVimeoUrl = value => {
  // eslint-disable-next-line no-useless-escape
  return !value || /^(http|https)?:\/\/(www\.)?vimeo.com\/(?:channels\/(?:\w+\/)?|groups\/([^\/]*)\/videos\/|)(\d+)(?:|\/\?)/.test(value)
}

const isYoutubeUrl = value => {
  // eslint-disable-next-line no-useless-escape
  return !value || /^(https?\:\/\/)?(www\.)?(youtube\.com|youtu\.be)\/.+$/.test(value)
}

export default {
  name: 'SolutionsManage',
  components: {
    Tabs: () => import('@/components/general/Tabs'),
    Icon: () => import('@/components/general/Icon'),
    Radio: () => import('@/components/general/Radio'),
    Upload: () => import('@/components/general/Upload'),
    Action: () => import('@/components/general/Action'),
    Divisor: () => import('@/components/general/Divisor'),
    Datatable: () => import('@/components/general/Datatable'),
    FileField: () => import('@/components/general/FileField'),
    ActionBar: () => import('@/components/general/ActionBar'),
    InputField: () => import('@/components/general/InputField'),
    Pagination: () => import('@/components/general/Pagination'),
    MoneyField: () => import('@/components/general/MoneyField'),
    FormSection: () => import('@/components/general/FormSection'),
    SelectField: () => import('@/components/general/SelectField'),
    MediaPreview: () => import('@/components/general/MediaPreview'),
    TextareaField: () => import('@/components/general/TextareaField'),
    ContentHeader: () => import('@/components/general/ContentHeader'),
    RichTextEditor: () => import('@/components/general/RichTextEditor'),
    AppMultiselect: () => import('@/components/general/AppMultiselect'),
    FilterListSearch: () => import('@/components/general/FilterListSearch')
  },

  data () {
    return {
      activeTab: 1,
      linkToSolutionTabActive: 0,
      linkToSolutionTab: [
        { text: 'solution.manage:link.tab.1' },
        { text: 'solution.manage:link.tab.2' }
      ],

      paths: [],
      thematicPages: [],
      solutions: [],
      configurationList: [],
      sasUnityList: [],
      sasProjectList: [],
      sasActionList: [],
      timeDurationList: [
        {
          text: this.$t('global.time:select.h'),
          value: 'h'
        },
        {
          text: this.$t('global.time:select.m'),
          value: 'min'
        }
        // { text: this.$t('global.time:select.d'), value: 'd' },
        // { text: this.$t('global.time:select.s'), value: 's' }
      ],

      minutesOptionsDurationList: {
        text: this.$t('global.time:select.m'),
        value: 'min'
      },

      themeList: [],
      groupList: [],
      targetAudienceList: [],
      recommendationTypeOptions: [
        {
          label: this.$t('global:solution.recommendation.type.option.themes'),
          value: 'themes'
        },
        {
          label: this.$t('global:solution.recommendation.type.option.target.audience'),
          value: 'target_audience'
        },
        {
          label: this.$t('global:solution.recommendation.type.option.solutions'),
          value: 'solutions'
        }
      ],

      restrictedAccessOptions: [
        {
          label: this.$t('global:unrestricted'),
          value: false
        },
        {
          label: this.$t('global:restricted'),
          value: true
        }
      ],

      yesNoOptions: [
        {
          label: this.$t('global:yes'),
          value: true
        },
        {
          label: this.$t('global:no'),
          value: false
        }
      ],

      classTypeOptions: [
        {
          label: this.$t('global:class.type.option.open'),
          value: 'open'
        },
        {
          label: this.$t('global:class.type.option.close'),
          value: 'closed'
        }
      ],

      enrollmentTypeOptions: [
        {
          label: this.$t('global:enrollment.type.option.open'),
          value: 'open'
        },
        {
          label: this.$t('global:enrollment.type.option.close'),
          value: 'closed'
        }
      ],

      solutionTypeList: [],
      formatList: [
        {
          text: this.$t('solutions.manage:formatList.elearning'),
          value: 'elearning'
        },
        {
          text: this.$t('solutions.manage:formatList.forum'),
          value: 'forum'
        },
        {
          text: this.$t('solutions.manage:formatList.ebook'),
          value: 'ebook'
        },
        {
          text: this.$t('solutions.manage:formatList.article'),
          value: 'article'
        },
        {
          text: this.$t('solutions.manage:formatList.podcast'),
          value: 'podcast'
        },
        {
          text: this.$t('solutions.manage:formatList.ar'),
          value: 'ar'
        },
        {
          text: this.$t('solutions.manage:formatList.qrcode'),
          value: 'qrcode'
        },
        {
          text: this.$t('solutions.manage:formatList.video'),
          value: 'video'
        },
        {
          text: this.$t('solutions.manage:formatList.infographic'),
          value: 'infographic'
        }
      ],

      certificateTypeList: [
        {
          text: this.$t('solutions.manage:certificateTypeList.course'),
          value: 'course'
        },
        {
          text: this.$t('solutions.manage:certificateTypeList.ebook'),
          value: 'ebook'
        },
        {
          text: this.$t('solutions.manage:certificateTypeList.track'),
          value: 'track'
        }
      ],

      certificateList: [],
      satisfactionSurveyList: [],
      subscriptionSurveyList: [],
      integrationsList: [],
      paymentTypes: [
        {
          label: this.$t('solutions.manage:paymentTypes.paid'),
          value: 'paid'
        },
        {
          label: this.$t('solutions.manage:paymentTypes.free'),
          value: 'free'
        }
      ],

      solutionPagination: {
        query: { name: '' },
        filter: {
          not_id: []
        },

        order: [],
        page: 1,
        actualPage: 1,
        lastPage: null,
        limit: 6,
        is_default_search: true
      },

      formData: {
        showPaths: false,
        showThematicPages: false,
        id: null,
        name: '',
        slug: '',
        callText: '',
        description: '',
        audience: '',
        content: [],
        differentials: '',
        solutionType: null,
        showOnPortalEnabled: false,
        exclusiveToCompanyEnabled: false,
        hasEvent: false,
        exclusiveToThematicPagesEnabled: false,
        certificateEnabled: false,
        // certificateType: null,
        certificateId: null,
        teaserLink: null,
        liveChat: false,
        image: null,
        cardImage: null,
        restrictedAccess: false,
        solutionGroups: null,
        solutionThemes: [],
        targetAudiences: [],
        tags: null,
        segment: null,
        problemToBeSolved: null,
        skillsDeveloped: null,
        integrationCourseId: null,
        conclusionGroup: null,
        triggerWords: null,
        portfolioId: null,
        sasId: null,
        sasUnityId: null,
        sasProjectId: null,
        sasActionId: null,
        rae_sync_session: false,
        rae_sync_enrollment: false,
        rae_exclusive_enrollment: false,
        classType: null,
        enrollmentType: null,
        conclusionDeadline: null,
        reenrollmentDeadline: null,
        surveyEnabled: false,
        surveyId: null,
        subscriptionSurveyEnabled: false,
        surveyRequired: false,
        subscriptionSurveyId: null,
        surveyRequiredForCertificate: false,
        workload: {
          type: null,
          number: null
        },

        seo: {
          title: null,
          description: null,
          keywords: null
        },

        linkedPaths: [],
        linkedThematicPages: [],
        fileId: null,
        file: null,
        video: null,
        podcast: null,
        podcastFile: null,
        externalLink: null,
        recommendationType: null,
        recommendedSolutions: [],
        serviceIntegration: {
          status: true,
          connectUrl: ''
        },

        paymentType: 'free',
        price: null,
        money: {
          decimal: ',',
          thousands: '.',
          prefix: 'R$ ',
          suffix: ' #',
          precision: 2,
          masked: false /* doesn't work with directive */
        }
      },

      allowSelectClassType: false,
      hasEvent: false,
      backUrl: { name: 'solutions.index' },
      modalConfirm: false,
      modalLeave: false,
      formatedUrl: '',
      uploadStatus: null,
      validPodcastUrl: null,
      isSlugDuplicate: false,
      content: null,
      pathsPagination: {
        query: {
          name: null,
          limit: 300
        },

        filter: {
          active: 1
        },

        order: {
          name: 'ASC'
        },

        lastPage: null,
        limit: 6,
        page: 1
      },

      isLoadingMorePaths: false,
      isLoadingMoreThematicPages: false,

      thematicPagesPagination: {
        query: {
          name: null,
          limit: 300
        },

        filter: {
          active: 1
        },

        order: {
          name: 'ASC'
        },

        lastPage: null,
        limit: 6,
        page: 1
      },

      pathInfiniteScrollId: new Date()
        .getTime(),

      thematicPageInfiniteScrollId: new Date()
        .getTime(),

      showIntegrations: true,

      paymentTypeOptions: [
        {
          label: this.$t('global:free'),
          value: 'free'
        },
        {
          label: this.$t('global:paid'),
          value: 'paid'
        }
      ],

      minimumSolutionPrice: 0.0,
      minimumSolutionPriceErrorMessage: null,

      integrationAlias: null,

      isValidPortfolioId: true,
      portfolioId: null
    }
  },

  validations () {
    return {
      formData: {
        name: { required },
        slug: {
          required,
          regexSlug: (event) => {
            const regexSlug = new RegExp('^[a-z0-9]+(?:-[a-z0-9]+)*$')

            return regexSlug.test(event)
          },

          slugDuplicate: () => {
            return this.isSlugDuplicate
          }
        },

        callText: { required },
        description: { required },
        audience: { required },
        differentials: { required },
        solutionType: { required },
        workload: {
          number: {
            required,
            minValue: minValue(1)
          },

          type: { required }
        },

        image: { required },
        cardImage: { required },
        classType: { required },
        enrollmentType: {
          required: requiredIf(function () {
            return this.isSolutionOpen
          })
        },

        restrictedAccess: { required },

        solutionGroups: {
          required: requiredIf(function () {
            return this.restrictedAccess
          }),

          minGroupsLength: (value, formData) => {
            return formData.restrictedAccess ? value ? value.length > 0 : true : true
          }
        },

        conclusionDeadline: {
          required: requiredIf(function () {
            return this.isSolutionOpen && ![
              'e-book',
              'video',
              'webinar',
              'podcast',
              'external_activity'
            ].includes(this.formData.solutionType)
          }),

          valueBiggerZero: (valorDigitado, formData) => {
            if (formData.classType === 'closed' ||
              formData.solutionType === 'e-book' ||
              formData.solutionType === 'video' ||
              formData.solutionType === 'webinar' ||
              formData.solutionType === 'podcast' ||
              formData.solutionType === 'external_activity') {
              return true
            }

            return valorDigitado > 0
          }
        },

        reenrollmentDeadline: {
          required: requiredIf(function () {
            return this.isSolutionOpen && ![
              'e-book',
              'video',
              'webinar',
              'podcast',
              'external_activity'
            ].includes(this.formData.solutionType)
          })
        },

        showOnPortalEnabled: {
          required: (event) => {
            return event !== null && event !== undefined
          }
        },

        exclusiveToThematicPagesEnabled: {
          required: requiredIf(function () {
            return !!this.formData.showThematicPages &&
              (this.formData.exclusiveToThematicPagesEnabled === null ||
                this.formData.exclusiveToThematicPagesEnabled === undefined)
          })
        },

        exclusiveToCompanyEnabled: {
          required: requiredIf(function () {
            return !!this.formData.showThematicPages &&
              (this.formData.exclusiveToCompanyEnabled === null ||
                this.formData.exclusiveToCompanyEnabled === undefined)
          })
        },

        certificateEnabled: {
          required: (event) => {
            return event !== null && event !== undefined
          }
        },

        certificateId: {
          required: requiredIf(function () {
            return !!this.formData.certificateEnabled
          })
        },

        integrationCourseId: {
          required: requiredIf(function () {
            return !this.isEditing && this.showIntegrationCode()
          })
        },

        portfolioId: {
          emptyRAE: () => {
            return !(this.formData.active && !!this.portfolioId && !this.formData.portfolioId)
          },

          valueBiggerZero: (valorDigitado) => {
            return valorDigitado ? valorDigitado > 0 : true
          },

          validatePortfolio: () => {
            return this.isValidPortfolioId
          }
        },

        sasId: {
          required: requiredIf(this.formData.hasEvent),
          valueBiggerZero: (valorDigitado) => {
            if (this.formData.hasEvent) {
              return valorDigitado ? valorDigitado > 0 : false
            }

            return true
          }
        },

        sasUnityId: {
          required: requiredIf(this.formData.hasEvent),
          valueBiggerZero: (valorDigitado) => {
            if (this.formData.hasEvent) {
              return valorDigitado ? valorDigitado > 0 : false
            }

            return true
          }
        },

        sasProjectId: {
          required: requiredIf(this.formData.hasEvent),
          valueBiggerZero: (valorDigitado) => {
            if (this.formData.hasEvent) {
              return valorDigitado ? valorDigitado > 0 : false
            }

            return true
          }
        },

        sasActionId: {
          required: requiredIf(this.formData.hasEvent),
          valueBiggerZero: (valorDigitado) => {
            if (this.formData.hasEvent) {
              return valorDigitado ? valorDigitado > 0 : false
            }

            return true
          }
        },

        rae_sync_session: {
          required: false
        },

        rae_sync_enrollment: {
          required: false
        },

        rae_exclusive_enrollment: {
          required: false
        },

        surveyEnabled: {
          required: (event) => {
            return event !== null && event !== undefined
          }
        },

        surveyId: {
          required: requiredIf(function () {
            return this.formData.surveyEnabled
          })
        },

        subscriptionSurveyEnabled: {
          required: (event) => {
            return event !== null && event !== undefined
          }
        },

        surveyRequired: {
          required: (event) => {
            return event !== null && event !== undefined
          }
        },

        subscriptionSurveyId: {
          required: requiredIf(function () {
            return this.formData.subscriptionSurveyEnabled
          })
        },

        conclusionGroup: {
          required: requiredIf(function () {
            return this.formData.solutionType === 'dicas_whatsapp' && this.integrationAlias === 'weni' && !this.isEditing
          })
        },

        triggerWords: {
          required: requiredIf(function () {
            return this.formData.solutionType === 'dicas_whatsapp' && !this.isEditing
          })
        },

        file: {
          required: requiredIf(function () {
            return this.formData.solutionType === 'e-book' && !this.isEditing
          })
        },

        video: {
          required: requiredIf(function () {
            return this.isVideo || this.isWebinar
          }),

          url,
          urlYoutubeVimeoValidator: (url) => {
            if (this.integrationAlias && this.integrationAlias === 'vimeo_youtube') {
              return isUrlYoutubeOrVimeo(url)
            }

            return true
          },

          youtubeOnly: (url) => {
            if (this.integrationAlias && this.integrationAlias === 'youtube_live') {
              return isYoutubeUrl(url)
            }

            return true
          },

          vimeoOnly: (url) => {
            if (this.integrationAlias && this.integrationAlias === 'vimeo') {
              return isVimeoUrl(url)
            }

            return true
          }

        },

        podcast: {
          required: requiredIf(function () {
            return this.isPodcast && !this.formData.podcastFile && ![
              'in_progress',
              'ready'
            ].includes(this.uploadStatus)
          }),

          url: () => {
            if (this.validPodcastUrl === null) {
              return true
            }

            return this.isPodcast && this.validPodcastUrl
          }
        },

        podcastFile: {
          required: requiredIf(function () {
            return this.isPodcast && !this.formData.podcast && ![
              'in_progress',
              'ready'
            ].includes(this.uploadStatus)
          })
        },

        externalLink: {
          required: requiredIf(function () {
            return this.isExternalActivity
          })
        },

        serviceIntegration: {
          status: {
            required: requiredIf(function () {
              return this.isVideoconferencia
            })
          }
        },

        integration: {
          required: requiredIf(function () {
            return this.showIntegrations
          })
        }
      }
    }
  },

  computed: {
    ...mapGetters([ 'activeModules' ]),

    availablePaths () {
      return this.paths.filter(path => !this.formData.linkedPaths.find(findPath => path.id === findPath.id))
    },

    availableThematicPages () {
      return this.thematicPages.filter(thematicPage => !this.formData.linkedThematicPages.find(
        findThematicPage => thematicPage.id === findThematicPage.id
      ))
    },

    availableSolutions () {
      return this.solutions.filter(solution => !this.formData.recommendedSolutions.find(
        findSolution => solution.id === findSolution.id
      ))
    },

    isEditing () {
      return !!(this.$route.params.id || this.formData.id || false)
    },

    selectedSolutionTypeText () {
      if (this.formData.solutionType === 'dicas_whatsapp') {
        return 'solution.manage:field.description.course.code.whats'
      }

      if (this.isEad) {
        return 'solution.manage:field.description.course.code.ead'
      }

      return ''
    },

    selectedSessionTypeText () {
      if (this.isSolutionOpen) {
        return 'solution.manage:field.description.session.type.open'
      }

      if (this.isSolutionClose) {
        return 'solution.manage:field.description.session.type.closed'
      }

      if (!this.formData.solutionType) {
        return 'solution.manage:field.description.session.type.false'
      }

      return ''
    },

    isSolutionOpen () {
      return this.formData.classType === 'open'
    },

    isSolutionClose () {
      return this.formData.classType === 'closed'
    },

    isSolutionPaid () {
      return this.formData.paymentType === 'paid'
    },

    selectedRecommendationTypeText () {
      if (this.formData.recommendationType === 'themes') {
        return 'solution.manage:field.description.recommendation.type.themes'
      }

      if (this.formData.recommendationType === 'target_audience') {
        return 'solution.manage:field.description.recommendation.type.target.audience'
      }

      if (this.formData.recommendationType === 'solutions') {
        return 'solution.manage:field.description.recommendation.type.solutions'
      }

      return ''
    },

    isEbook () {
      return this.formData.solutionType === 'e-book'
    },

    isEad () {
      return this.formData.solutionType === 'curso_ead'
    },

    isVideo () {
      return this.formData.solutionType === 'video'
    },

    isWebinar () {
      return this.formData.solutionType === 'webinar'
    },

    isPodcast () {
      return this.formData.solutionType === 'podcast'
    },

    isExternalActivity () {
      return this.formData.solutionType === 'external_activity'
    },

    isLocalIntegration () {
      return this.isEbook || this.isVideo || this.isWebinar || this.isPodcast || this.isExternalActivity
    },

    isVideoconferencia () {
      return this.formData.solutionType === 'videoconferencia'
    },

    isPresencial () {
      return this.formData.solutionType === 'presencial'
    },

    isEadMoodle () {
      return this.formData.solutionType === 'curso_ead_moodle'
    },

    shouldDisableRaeSyncSession () {
      return this.isSolutionOpen || this.isPresencial
    },

    shouldDisableRaeSyncEnrollment () {
      return (this.isSolutionOpen || !this.formData.rae_sync_session) || this.isPresencial
    },

    shouldDisableRaeExclusiveEnrollment () {
      return (this.isSolutionOpen || !this.formData.rae_sync_enrollment) || this.isPresencial
    },

    shouldDisableClassType () {
      return (!this.allowSelectClassType || this.isEditing) || this.isPresencial
    },

    isRaeEnabled () {
      return this.activeModules.includes('rae')
    },

    isEcommerceEnabled () {
      return this.activeModules.includes('ecommerce')
    },

    isClosedSessionEnabled () {
      return this.activeModules.includes('closed-session')
    },

    isSasEnabled () {
      return this.activeModules.includes('sas')
    },

    shouldDisableEnrollmentType () {
      return !this.isEcommerceEnabled && this.isSolutionPaid
    },

    isActiveTargetAudienceModule () {
      return this.activeModules.includes('target-audience')
    },

    availableTargetRecommendations () {
      if (!this.isActiveTargetAudienceModule) {
        return this.recommendationTypeOptions.filter(item => item.value !== 'target_audience')
      }

      return this.recommendationTypeOptions
    },

    isThematicPageEnabled () {
      return this.activeModules.includes('thematic-page')
    }
  },

  watch: {
    'formData.solutionType' (event) {
      const selectedType = this.solutionTypeList.find(type => type.value === event)

      this.allowSelectClassType = true

      if (selectedType && !this.isEditing) {
        this.allowSelectClassType = selectedType.isAllowClosedSession && selectedType.isAllowOpenedSession
        this.formData.classType = !this.isClosedSessionEnabled ? 'open' : !this.allowSelectClassType && selectedType.isAllowClosedSession ? 'closed' : 'open'
        this.formData.enrollmentType = this.isSolutionOpen ? 'open' : null

        this.formData.serviceIntegration.status = true

        if (this.isVideoconferencia) {
          this.pingServiceIntegration('msteams')
        }

        this.getSolutionTypeIntegrations()
      }
    },

    'formData.integration' (event) {
      if (this.integrationsList.length === 0) return
      const selectedIntegration = this.integrationsList.find(integration => integration.value === event)

      this.integrationAlias = selectedIntegration.alias

      if (this.integrationAlias === 'youtube_live') {
        this.formData.classType = 'closed'
        this.allowSelectClassType = false
      }
    },

    'isEbook' (event) {
      if (event) {
        this.formData.certificateEnabled = false
        this.formData.certificateId = null
      }
    },

    'isPresencial' (event) {
      if (event) {
        this.formData.certificateEnabled = false
        this.formData.certificateId = null
        this.formData.classType = 'closed'
        this.formData.surveyEnabled = false
        this.formData.rae_sync_session = true
        this.formData.rae_sync_enrollment = true
        this.formData.rae_exclusive_enrollment = false
        this.formData.portfolioId = this.formData.integrationCourseId
      }
    },

    'formData.sasProjectId' (event) {
      this.sasActionList = []

      if (event > 0) {
        this.setFetching(true)

        this.getSasActionList(event)
          .finally(() => {
            if (event && !this.formData.sasActionId) {
              const defaultSasActionId = this.configurationList.find(config => config.text === 'sas_id_action')

              this.formData.sasActionId = parseInt(defaultSasActionId.value, 10)
            }

            this.setFetching(false)
          })
      }
    },

    'formData.hasEvent' (event) {
      if (event) {
        if (!this.formData.sasUnityId) {
          const defaultSasUnityId = this.configurationList.find(config => config.text === 'sas_id_unity')

          this.formData.sasUnityId = parseInt(defaultSasUnityId.value, 10)
        }

        if (!this.formData.sasProjectId) {
          const defaultSasProjectId = this.configurationList.find(config => config.text === 'sas_id_project')

          this.formData.sasProjectId = parseInt(defaultSasProjectId.value, 10)
        }
      }
    },

    'formData.rae_sync_session' () {
      if (!this.formData.rae_sync_session) {
        this.formData.rae_sync_enrollment = false
      }
    },

    'formData.rae_sync_enrollment' () {
      if (!this.formData.rae_sync_enrollment) {
        this.formData.rae_exclusive_enrollment = false
      }
    },

    isPodcast (event) {
      if (event && !this.isEditing) {
        this.formData.workload.type = 'min'
      }
    },

    'formData.name' () {
      if (!this.$v.formData.slug.$dirty && !this.isEditing) {
        this.formData.slug = formatSlug(this.formData.name)
      }
    },

    'formData.slug' (event) {
      this.formData.slug = event.replace(new RegExp('[-]{2,}', 'g'), '-')

      this.debounceEvent(async () => {
        this.setFetching(true)
        const search = { slug: event }

        if (this.isEditing) {
          search.not_id = [ this.formData.id ]
        }

        const solutions = await this.attemptGetSolutionsList({ filter: search })
          .then(pagination => { return pagination.data })

        this.isSlugDuplicate = !(solutions.length > 0)

        if (!this.isSlugDuplicate) {
          this.$v.formData.slug.$touch()
        }

        this.setFetching(false)
      }, 1000)

      this.formatedUrl = `${process.env.VUE_APP_PORTAL_URL}curso/${this.formData.slug}`
    },

    'solutionPagination.page' () {
      if (this.solutionPagination.actualPage !== this.solutionPagination.page) {
        this.searchSolutions()
      }
    },

    'solutionPagination.query.name' () {
      if (this.solutionPagination.query && this.solutionPagination.query.name.length >= 0) {
        this.solutionPagination.page = 1
        this.searchSolutions()
      }
    },

    'formData.recommendationType' (value) {
      if (value === 'solutions' && this.solutions.length === 0) {
        this.getSolutions()
      }
    },

    'formData.price' (value) {
      this.minimumSolutionPriceErrorMessage = value < this.minimumSolutionPrice ? 'Esse valor é menor do que a parcela mínima' : null
    },

    'formData.paymentType' (value) {
      if (value === 'free' && this.formData.price === 0.0) {
        this.minimumSolutionPriceErrorMessage = null
      } else if (value === 'paid' && !this.isEcommerceEnabled) {
        this.formData.enrollmentType = 'closed'
      }
    },

    'activeTab' (value) {
      if (value === 5 && this.formData.active && this.portfolioId && this.isRaeEnabled) {
        this.validatePortfolio()
      }
    },

    isThematicPageEnabled: {
      immediate: true,
      deep: true,
      handler (value) {
        if (!value) {
          this.linkToSolutionTab = [{ text: 'solution.manage:link.tab.1' }]
        }
      }
    }
  },

  mounted () {
    if (this.$route.params.isNew) {
      this.activeTab = 2
    } else if (this.$route.params.tab) {
      this.activeTab = this.$route.params.tab
    }
  },

  created () {
    this.setup()
    this.setFetching(true)

    Promise.all([
      this.getCertificatesList(),
      this.getSurveysList(),
      this.getThemesList(),
      this.getGroupsList(),
      this.getTargetAudiencesList(),
      this.getLearningPathsList(),
      this.getThematicPagesList(),
      this.getSolutionsTypesList(),
      this.isSasEnabled ? this.getSasUnityList() : null,
      this.isSasEnabled ? this.getSasProjectList() : null,
      this.getGeneralSettingsList()
    ])
      .catch(() => {
        this.setFeedback({ message: this.$t('solutions.load:fetch.error') })
        this.$router.push({ name: 'solutions.index' })
      })
      .finally(() => {
        if (this.isEditing) {
          this.setFetching(true)

          this.attemptManageSolution(this.$route.params.id)
            .then((response) => {
              this.formData = this.hydrateSolution(this.formData, response)

              this.formData.solutionGroups = response.groupSolution.map(item => {
                return {
                  text: item.group.name,
                  value: item.group.id
                }
              })

              this.formData.restrictedAccess = response.restrictedAccess
              this.formData.solutionType = this.formData.solutionType.alias
              this.formData.integration = this.formData.solutionTypeIntegration.id
              this.integrationAlias = this.formData.solutionTypeIntegration.integration.alias
              this.portfolioId = this.formData.portfolioId

              if (typeof this.formData.workload === 'string') {
                this.formData.workload = {
                  type: this.formData.workload
                    ? this.formData.workload.match(/[hdw|min]+/gi) && this.formData.workload.match(/[hdw|min]+/gi)[0]
                    : null,
                  number: this.formData.workload
                    ? this.formData.workload.match(/[0-9]+/gi) && this.formData.workload.match(/[0-9]+/gi)[0]
                    : null
                }
              }

              if ((this.isVideo || this.isWebinar) && this.formData.solutionAsset.length > 0) {
                const assetVideo = this.formData.solutionAsset.find(solutionAsset => solutionAsset.asset.assetType.alias === 'video')

                this.formData.video = this.isEditing ? assetVideo ? assetVideo.asset.meta.video_url : null : null
              }

              if (this.isPodcast && this.formData.solutionAsset.length > 0) {
                this.formData.podcast = this.isEditing ? this.formData.solutionAsset[0].asset.meta.podcast_url : null
                this.validPodcastUrl = true
              }

              if (this.isExternalActivity && this.formData.solutionAsset.length > 0) {
                this.formData.externalLink = this.isEditing ? this.formData.solutionAsset[0].asset.meta.url : null
              }

              if (this.isWebinar && this.formData.solutionAsset.length > 1 && this.isSolutionClose) {
                this.formData.liveChat = this.isEditing ? this.formData.solutionAsset.some(solutionAsset => solutionAsset.asset.meta.chat === 'true') : false
              }

              this.uploadStatus = this.formData.metadata && this.formData.metadata.uploadStatus
                ? this.formData.metadata.uploadStatus
                : null

              this.formData.recommendationType = this.formData.recommendationType || 'themes'
              this.fetchConnections(response.groupSolution)
              this.hasEvent = this.formData.hasEvent

              this.getSolutionTypeIntegrations()
            })
            .catch(() => {
              this.setFeedback({ message: this.$t('solutions.load:fetch.error') })
              this.$router.push({ name: 'solutions.index' })
            })
            .finally(() => {
              this.setFetching(false)
            })
        } else {
          this.formData.workload.type = this.timeDurationList[0].value
          this.setFetching(false)
        }

        this.$v.$reset()
      })
  },

  methods: {
    ...mapActions([
      'setFetching',
      'setFeedback',
      'attemptUpdateSolution',
      'attemptUpdateActiveSolution',
      'attemptSaveActiveSolution',
      'attemptGetCertificatesList',
      'attemptGetSurveysList',
      'attemptGetThemesList',
      'attemptGetGroupsList',
      'attemptGetTargetAudiencesList',
      'attemptManageSolution',
      'attemptGetLearningPathsList',
      'attemptGetThematicPagesList',
      'attemptGetSasUnityList',
      'attemptGetSasProjectList',
      'attemptGetSasActionList',
      'attemptGetSolutionsTypesList',
      'attemptPingServiceIntegration',
      'attemptGetGeneralSettings',
      'attemptGetSolutionsList',
      'attemptGetSolutionAsset',
      'attemptGetVimeoDetails',
      'attemptGetSolutionTypeIntegration',
      'attemptValidatePortfolio'
    ]),

    fillSeoData () {
      if (this.activeTab === 3) {
        this.formData.seo.title = this.formData.seo.title ? this.formData.seo.title : this.formData.name

        this.formData.seo.description = this.formData.seo.description
          ? this.formData.seo.description
          : this.formData.callText
      }
    },

    prevPage () {
      this.submit()
        .then(() => {
          if (this.activeTab > 1) this.activeTab -= 1
        })
    },

    nextPage () {
      this.$v.$touch()

      if (!this.$v.$invalid) {
        this.setFetching(true)
        this.fillSeoData()

        if (this.formData.id != null) {
          const formData = this.formatFormData()

          this.attemptUpdateActiveSolution({
            id: this.$route.params.id,
            data: formData
          })
            .then((response) => {
              this.activeTab += 1
              const path = `/solutions/manage/${response.data.id}`

              if (this.$route.path !== path) {
                this.$router.push({
                  name: 'solutions.manage',
                  params: {
                    id: response.data.id,
                    isNew: 'edit'
                  }
                })

                this.setFeedback({ message: this.$t('solutions:feedback.updated.success') })
              }
            })
            .catch((error) => {
              const message = this.getParsedErrorMessage(error)

              this.setFeedback({ message: message })
            })
            .finally(() => {
              this.setFetching(false)
            })
        } else {
          const formData = this.formatFormData()

          this.attemptSaveActiveSolution({ data: formData })
            .then((response) => {
              this.activeTab += 1
              this.formData.id = response.data.id

              this.$router.push({
                name: 'solutions.manage',
                params: {
                  id: response.data.id,
                  isNew: 'new'
                }
              })

              this.setFeedback({ message: this.$t('solutions:feedback.created.success') })
            })
            .catch((error) => {
              const message = this.getParsedErrorMessage(error)

              this.setFeedback({ message: message })
            })
            .finally(() => {
              this.setFetching(false)
            })
        }
      } else {
        this.setFeedback({ message: this.$t('management.certificate:feedback.validation.error') })
      }
    },

    paginationPrevPage (pagination) {
      if (pagination.page > 1) {
        pagination.page -= 1
      }
    },

    paginationNextPage (pagination) {
      pagination.page += 1
    },

    paginationFirstPage (pagination) {
      pagination.page = 1
    },

    paginationLastPage (pagination) {
      pagination.page = pagination.lastPage
    },

    formatFormData () {
      const data = {}
      const integrationData = {
        vendorId: !this.isLocalIntegration ? this.formData.integrationCourseId : null
      }

      if (this.integrationAlias === 'weni') {
        integrationData.vendorFinishId = this.formData.conclusionGroup
        integrationData.vendorKey = this.formData.triggerWords
      }

      if (this.integrationAlias === 'watson') {
        // TODO: Remover esse campo para watson, pois não é mais necessário. Porem precisar ajustar na API.
        integrationData.vendorFinishId = this.formData.triggerWords
      }

      data.solution_type = this.formData.solutionType
      data.integration = this.formData.integration
      data.solution_integration_data = JSON.stringify(integrationData)
      data.name = this.formData.name
      data.slug = this.formData.slug
      data.description = this.formData.description
      data.format = this.formData.format
      data.call_text = this.formData.callText
      data.groups = this.formData.restrictedAccess ? this.formData.solutionGroups.filter(item => item.value !== 'all').map(item => item.value) : []
      data.restricted_access = this.formData.restrictedAccess ? 1 : 0
      data.audience = this.formData.audience
      data.content = this.formData.content
      data.differentials = this.formData.differentials
      // data.certificate_type = this.formData.certificateType
      data.payment_type = this.formData.paymentType
      data.price = this.formData.price
      data.teaser_link = this.formData.teaserLink
      data.image = this.formData.image
      data.card_image = this.formData.cardImage
      data.portfolio_id = this.formData.portfolioId || null
      data.sas_id = this.formData.sasId || null
      data.sas_unity_id = this.formData.hasEvent && this.formData.sasUnityId ? this.formData.sasUnityId : null
      data.sas_project_id = this.formData.hasEvent && this.formData.sasProjectId ? this.formData.sasProjectId : null
      data.sas_action_id = this.formData.hasEvent && this.formData.sasActionId ? this.formData.sasActionId : null
      data.rae_sync_session = this.formData.rae_sync_session ? 1 : 0
      data.rae_sync_enrollment = this.formData.rae_sync_enrollment ? 1 : 0
      data.rae_exclusive_enrollment = this.formData.rae_exclusive_enrollment ? 1 : 0
      data.show_on_portal = this.formData.showOnPortalEnabled ? 1 : 0
      data.certificate_id = this.formData.certificateEnabled ? this.formData.certificateId : null
      data.session_type = this.formData.classType
      data.enrollment_type = this.isSolutionOpen ? this.formData.enrollmentType : null
      data.session_due_time = this.isSolutionOpen ? this.formData.conclusionDeadline : null
      data.session_reenrollment_await_time = this.isSolutionOpen ? this.formData.reenrollmentDeadline : null
      data.video = (this.isVideo || this.isWebinar) ? this.formData.video : null
      data.live_chat = this.isSolutionClose && this.isWebinar ? this.formData.liveChat : null

      data.survey_ids = []

      if (this.formData.surveyEnabled) {
        data.survey_ids.push(this.formData.surveyId)
      }

      if (this.formData.subscriptionSurveyEnabled) {
        data.survey_ids.push(this.formData.subscriptionSurveyId)
        data.survey_required = this.formData.surveyRequired
      }

      data.survey_required_for_certificate = this.formData.certificateEnabled && this.formData.surveyEnabled
        ? (this.formData.surveyRequiredForCertificate ? 1 : 0)
        : 0

      data.podcast_file = this.isPodcast ? this.formData.podcastFile : null
      data.podcast = this.isPodcast && !data.podcast_file ? this.formatVimeoUrl(this.formData.podcast) : null

      if (data.podcast_file) {
        this.uploadStatus = 'ready'
      }

      data.external_link = this.isExternalActivity ? this.formData.externalLink : null

      if (this.isEbook || this.isVideo || this.isWebinar || this.isPodcast || this.isExternalActivity) {
        data.session_due_time = 0
        data.session_reenrollment_await_time = 0
      }

      data.file = this.formData.file
      data.file_id = this.formData.fileId

      data.themes = this.formData.solutionThemes ? this.formData.solutionThemes : []
      data.target_audiences = this.formData.targetAudiences ? this.formData.targetAudiences : []

      data.workload = this.formData.workload.number
        ? (this.formData.workload.number + this.formData.workload.type)
        : '0h'

      data.tags = this.formData.tags

      data.seo_title = this.formData.seo.title
      data.seo_description = this.formData.seo.description
      data.seo_keywords = this.formData.seo.keywords

      data.learning_paths = []

      this.formData.linkedPaths.forEach(learningPath => {
        data.learning_paths.push(learningPath.id)
      })

      data.exclusive_to_thematic_pages = this.formData.exclusiveToThematicPagesEnabled ? 1 : 0
      data.exclusive_to_company = this.formData.exclusiveToCompanyEnabled ? 1 : 0
      data.has_event = this.formData.hasEvent ? 1 : 0
      data.thematic_pages = []

      this.formData.linkedThematicPages.forEach(thematicPage => {
        data.thematic_pages.push(thematicPage.id)
      })

      // data.format = this.formData.format
      // data.content = this.formData.content
      // data.teaser_link = this.formData.teaserLink

      // data.active = this.formData.active || false

      data.recommendation_type = this.formData.recommendationType || 'themes'
      data.solutions_recommendations = []

      if (data.recommendation_type === 'solutions') {
        this.formData.recommendedSolutions.forEach(solution => {
          data.solutions_recommendations.push(solution.id)
        })
      }

      return data
    },

    submitCreation () {
      this.setFetching(true)

      this.attemptSaveActiveSolution({ data: this.formatFormData() })
        .then(({ data }) => {
          this.$router.push({
            name: 'solutions.manage',
            params: {
              id: data.id,
              isNew: 'edit'
            }
          })

          this.setFeedback({ message: this.$t('solutions:feedback.created.success') })
        })
        .catch((response) => {
          const message = this.getParsedErrorMessage(response)

          this.setFeedback({ message: message })
        })
        .finally(() => {
          this.setFetching(false)
        })
    },

    addToLinkedPath (pathId) {
      this.formData.linkedPaths.push(this.paths.find(path => path.id === pathId))
    },

    removeLinkedPath (pathId) {
      this.formData.linkedPaths = this.formData.linkedPaths.filter(path => path.id !== pathId)
    },

    addToLinkedThematicPage (thematicPageId) {
      const thematicPage = this.thematicPages.find(thematicPage => thematicPage.id === thematicPageId)

      thematicPage.active = 1
      this.formData.linkedThematicPages.push(thematicPage)
    },

    removeLinkedThematicPage (thematicPageId) {
      this.formData.linkedThematicPages = this.formData.linkedThematicPages.filter(
        thematicPage => thematicPage.id !== thematicPageId
      )
    },

    clearLinkedPaths (event) {
      if (event) return

      this.formData.linkedPaths = this.formData.linkedPaths.filter(linkedPath =>
        linkedPath.active !== 1
      )
    },

    clearLinkedThematicPages (event) {
      if (event) return

      this.formData.linkedThematicPages = this.formData.linkedThematicPages.filter(linkedThematicPage =>
        linkedThematicPage.active !== 1
      )
    },

    addToRecommededSolutions (solutionId) {
      if (this.formData.recommendedSolutions.length >= 3) {
        this.setFeedback({ message: this.$t('solutions:feedback.max.recommendations.error') })

        return
      }

      this.formData.recommendedSolutions.push(this.availableSolutions.find(solution => solution.id === solutionId))
    },

    removeRecommendedSolution (solutionId) {
      this.formData.recommendedSolutions = this.formData.recommendedSolutions.filter(
        solution => solution.id !== solutionId
      )
    },

    addToContent () {
      if (this.content === null || this.content.trim() === '') {
        return
      }

      if (this.formData.content.length >= 10) {
        this.setFeedback({ message: this.$t('solutions:feedback.max.content.error') })

        return
      }

      this.formData.content.push(this.content.trim())
      this.content = null
    },

    removeFromContent (index) {
      this.formData.content.splice(index, 1)
    },

    submitUpdate () {
      this.setFetching(true)
      const formData = this.formatFormData()

      this.attemptUpdateSolution({
        id: this.$route.params.id,
        data: formData
      })
        .then(({ data }) => {
          if (data.imagePath) {
            data.imagePath = data.imagePath + '?v=' + Math.random()
          }

          this.setFeedback({ message: this.$t('solutions:feedback.updated.success') })

          this.hasEvent = this.formData.hasEvent
          this.fetchConnections(data.groupSolution)
        })
        .catch((response) => {
          const message = this.getParsedErrorMessage(response)

          this.setFeedback({ message: message })
        })
        .finally(() => {
          this.setFetching(false)
        })
    },

    submit (next) {
      return new Promise((resolve, reject) => {
        this.$v.$touch()

        if (!this.$v.$invalid) {
          this.$route.params.id ? this.submitUpdate(next) : this.submitCreation(next)
          resolve(true)
        } else {
          this.setFeedback({ message: this.$t('solutions:feedback.validation.error') })
          reject(new Error('Error'))
        }
      })
    },

    leave () {
      this.modalConfirm = false

      this.$nextTick(() => {
        this.$router.push(this.backUrl)
      })
    },

    getCertificatesList () {
      const pagination = {
        filter: {
          active: true
        },

        page: 1,
        limit: 999999
      }

      return this.attemptGetCertificatesList(pagination)
        .then(pagination => {
          if (pagination.data) {
            pagination.data.forEach(element => {
              this.certificateList.push({
                text: element.name,
                value: element.id
              })
            })

            return true
          }
        })
    },

    getSurveysList () {
      return this.attemptGetSurveysList({ filter: { active: 1 } })
        .then(pagination => {
          if (pagination.data) {
            pagination.data.forEach(element => {
              switch (element.type) {
                case 'satisfaction':
                  this.satisfactionSurveyList.push({
                    text: element.name,
                    value: element.id
                  })

                  break
                case 'subscription':
                  this.subscriptionSurveyList.push({
                    text: element.name,
                    value: element.id
                  })

                  break
              }
            })

            return true
          }
        })
    },

    getThemesList () {
      return this.attemptGetThemesList({})
        .then(pagination => {
          if (pagination.data) {
            pagination.data.forEach(element => {
              this.themeList.push({
                text: element.name,
                value: element.alias
              })
            })

            return true
          }
        })
    },

    getGroupsList () {
      return this.attemptGetGroupsList({
        filter: {
          active: true
        },
        limit: 9999
      })
        .then(pagination => {
          if (pagination.data) {
            pagination.data.forEach(element => {
              this.groupList.push({
                text: element.name,
                value: element.id
              })
            })

            return true
          }
        })
    },

    getTargetAudiencesList () {
      return this.attemptGetTargetAudiencesList({})
        .then(pagination => {
          if (pagination.data) {
            pagination.data.forEach(element => {
              this.targetAudienceList.push({
                text: element.name,
                value: element.id
              })
            })

            return true
          }
        })
    },

    getLearningPathsList () {
      this.pathsPagination.filter.is_restricted_access = this.formData.restrictedAccess
      this.pathsPagination.filter.solution_id = this.formData.id

      return this.attemptGetLearningPathsList(this.pathsPagination)
        .then(pagination => {
          if (pagination.data) {
            this.paths = []
            this.pathsPagination.lastPage = pagination.lastPage
            this.pathsPagination.page = pagination.actualPage

            pagination.data.forEach(element => {
              this.paths.push({
                name: element.name,
                id: element.id,
                learningPathSolutions: element.learningPathSolutions.length,
                workload: element.workload
              })
            })
          }
        })
    },

    getThematicPagesList () {
      this.thematicPagesPagination.filter.is_restricted_access = this.formData.restrictedAccess
      this.thematicPagesPagination.filter.solution_id = this.formData.id

      return this.attemptGetThematicPagesList(this.thematicPagesPagination)
        .then(pagination => {
          if (pagination.data) {
            this.thematicPages = []
            this.thematicPagesPagination.lastPage = pagination.lastPage
            this.thematicPagesPagination.page = pagination.actualPage

            pagination.data.forEach(element => {
              this.thematicPages.push({
                name: element.name,
                id: element.id,
                thematicPagesProgram: element.thematicPagesProgram,
                thematicPageLearningPaths: element.thematicPageLearningPaths,
                thematicPageSolutions: element.thematicPageSolutions
              })
            })

            return true
          }
        })
    },

    getSolutionsTypesList () {
      const pagination = {
        filter: {
          active: true
        },

        order: {
          name: 'asc'
        }
      }

      return this.attemptGetSolutionsTypesList(pagination)
        .then(pagination => {
          if (pagination.data) {
            pagination.data.forEach(element => {
              this.solutionTypeList.push({
                text: element.name,
                value: element.alias,
                isAllowClosedSession: element.allowClosedSession,
                isAllowOpenedSession: element.allowOpenedSession
              })
            })

            // TODO: filter temporário para remover a solução Canvas no ambiente de homologação.
            // this.solutionTypeList = this.solutionTypeList.filter((value, index) => index !== 0)

            return true
          }
        })
    },

    searchSolutions () {
      this.debounceEvent(() => {
        this.getSolutions()
      }, 1500)
    },

    getSolutions () {
      this.setFetching(true)

      this.solutionPagination.is_default_search = this.solutionPagination.page === 1 &&
        this.solutionPagination.query.name.length === 0

      this.solutionPagination.filter.not_id = this.formData.id ? [ this.formData.id ] : []

      this.attemptGetSolutionsList(this.solutionPagination)
        .then(pagination => {
          this.solutionPagination.lastPage = pagination.lastPage
          this.solutionPagination.actualPage = pagination.page
          this.solutions = []

          if (pagination.data) {
            pagination.data.forEach(element => {
              this.solutions.push({
                name: element.name,
                id: element.id
              })
            })

            return true
          }
        })
        .finally(() => {
          this.setFetching(false)
        })
    },

    getSasUnityList () {
      return this.attemptGetSasUnityList({
        filter: {
          active: 'true',
          id_type: 5
        },
        order: { description: 'ASC' }
      })
        .then(pagination => {
          if (pagination.data) {
            pagination.data.forEach(element => {
              this.sasUnityList.push({
                text: element.vendorId + ' - ' + element.description,
                value: element.id
              })
            })

            return true
          }
        })
    },

    getSasActionList (sasProjectId) {
      return this.attemptGetSasActionList({
        filter: {
          active: 'true',
          project: sasProjectId
        },
        order: { description: 'ASC' }
      })
        .then(pagination => {
          if (pagination.data) {
            pagination.data.forEach(element => {
              this.sasActionList.push({
                text: element.vendorId + ' - ' + element.description,
                value: element.id
              })
            })

            return true
          }
        })
    },

    getSasProjectList () {
      return this.attemptGetSasProjectList({
        filter: {
          active: 'true',
          action: 'true'
        },
        order: { description: 'ASC' }
      })
        .then(pagination => {
          if (pagination.data) {
            pagination.data.forEach(element => {
              this.sasProjectList.push({
                text: element.vendorId + ' - ' + element.description,
                value: element.id
              })
            })

            return true
          }
        })
    },

    getGeneralSettingsList () {
      return this.attemptGetGeneralSettings()
        .then(({ data }) => {
          if (data.data) {
            data.data.forEach(element => {
              this.configurationList.push({
                text: element.field,
                value: element.value
              })
            })

            this.setMinimumSolutionPrice()

            return true
          }
        })
    },

    blur (event) {
      event.target.blur()
    },

    showIntegrationCode () {
      if (this.isLocalIntegration || this.isVideoconferencia || !this.formData.solutionType || this.isEadMoodle) {
        return false
      }

      return true
    },

    pingServiceIntegration (alias) {
      this.setFetching(true)

      this.attemptPingServiceIntegration(alias)
        .then((data) => {
          this.formData.serviceIntegration.status = (data.ping === 'OK')
          this.formData.serviceIntegration.connectUrl = data.url
        })
        .finally(() => {
          this.setFetching(false)
        })
    },

    connectServiceIntegration () {
      window.open(this.formData.serviceIntegration.connectUrl)
      this.formData.serviceIntegration.status = true
      this.formData.solutionType = ''
    },

    showMediaPreview () {
      return !!(((this.isVideo || this.isWebinar) && this.formData.video) || (this.isPodcast && this.formData.podcast))
    },

    getMediaPreviewUrl () {
      if ((this.isVideo || this.isWebinar) && this.formData.video) {
        return this.formData.video
      }

      if (this.isPodcast && this.formData.podcast) {
        const vimeoUrl = this.formatVimeoUrl(this.formData.podcast)

        this.isValidVimeoUrl(vimeoUrl)

        return vimeoUrl
      }

      return null
    },

    getParsedErrorMessage (response) {
      if (response.data.error.message === 'upload_file_extension_not_allowed') {
        if (this.isEbook) {
          return this.$t('solutions:feedback.extension.ebook.error')
        }

        if (this.isPodcast) {
          if (!this.isEditing) {
            this.uploadStatus = null
          }

          return this.$t('solutions:feedback.extension.podcast.error')
        }

        return this.$t('solutions:feedback.extension.error')
      }

      if (response.data.error && response.data.error.validation) {
        if (response.data.error.validation.video === 'video_must_be_valid_vimeo_link') {
          return this.$t('solutions:feedback.url.vimeo.error')
        }

        if (response.data.error.validation.video === 'video_must_be_valid_youtube_or_vimeo_link') {
          return this.$t('solutions:feedback.url.youtube.vimeo.error')
        }

        if (response.data.error.validation.video === 'webinar_must_be_valid_youtube_or_vimeo_link') {
          return this.$t('solutions:feedback.url.webinar.youtube.vimeo.error')
        }

        if (response.data.error.validation.external_link === 'external_link_must_be_valid_url') {
          return this.$t('solutions:feedback.url.externalActivity.error')
        }
      }

      if (response.data.error.message === 'sas_unable_to_get_event') {
        return this.$t('solutions:feedback.sas.unable.get.event')
      }

      if (response.data.error.message === 'sas_get_event_error') {
        return this.$t('solutions:feedback.sas.get.event.error')
      }

      if (response.data.error.message === 'solution__invalid_portfolio') {
        return this.$t('solutions:feedback.portfolio.invalid')
      }

      if (response.data.error.code === 10) {
        return response.data.error.message
      }

      return this.$t('solutions:feedback.created.error')
    },

    viewEbookSolutionAsset () {
      if (this.formData.solutionAsset.length > 0) {
        const idAsset = this.formData.solutionAsset[0].asset.id
        const fileName = this.formData.file && this.formData.file.length > 0
          ? this.formData.file[0].name
          : this.formData.solutionAsset[0].asset.name

        this.setFetching(true)

        this.attemptGetSolutionAsset(idAsset)
          .then((response) => {
            const blob = new Blob([ response.data ])
            const link = document.createElement('a')

            link.href = window.URL.createObjectURL(blob)
            link.download = fileName
            link.click()
          })
          .catch(() => {
            this.setFeedback({ message: this.$t('solutions.asset.file:fetch.error') })
          })
          .finally(() => {
            this.setFetching(false)
          })
      }
    },

    searchPath (search) {
      this.pathsPagination.query.name = search
      this.pathsPagination.page = 1
      this.pathsPagination.lastPage = null
      this.fetchPathsData()
    },

    loadMorePaths () {
      this.isLoadingMorePaths = true

      if (this.pathsPagination.lastPage) {
        if (this.pathsPagination.lastPage > this.pathsPagination.page) {
          this.pathsPagination.page += 1
        } else {
          this.isLoadingMorePaths = false

          return
        }
      }

      this.fetchPathsData(true)
        .then(() => {
          this.isLoadingMorePaths = false
        })
    },

    async fetchPathsData (isLoadMore = false) {
      this.pathsPagination.filter.only_free = !this.formData.restrictedAccess

      return this.attemptGetLearningPathsList(this.pathsPagination)
        .then((pagination) => {
          this.pathInfiniteScrollId += 1
          this.pathsPagination.lastPage = pagination.lastPage
          this.pathsPagination.page = pagination.actualPage

          if (pagination.data) {
            if (!isLoadMore) {
              this.paths = []
            }

            pagination.data.forEach(element => {
              this.paths.push({
                name: element.name,
                id: element.id,
                thematicPageLearningPaths: element.thematicPageLearningPaths,
                thematicPageSolutions: element.thematicPageSolutions,
                learningPathSolutions: element.learningPathSolutions.length,
                workload: element.workload
              })
            })
          }

          return pagination
        })
    },

    searchThematicPage (search) {
      this.thematicPagesPagination.query.name = search
      this.thematicPagesPagination.page = 1
      this.thematicPagesPagination.lastPage = null
      this.fetchThematicPagesData()
    },

    loadMoreThematicPages () {
      this.isLoadingMoreThematicPages = true

      if (this.thematicPagesPagination.lastPage) {
        if (this.thematicPagesPagination.lastPage > this.thematicPagesPagination.page) {
          this.thematicPagesPagination.page += 1
        } else {
          this.isLoadingMoreThematicPages = false

          return
        }
      }

      this.fetchThematicPagesData(true)
        .then(() => {
          this.isLoadingMoreThematicPages = false
        })
    },

    async fetchThematicPagesData (isLoadMore = false) {
      return this.attemptGetThematicPagesList(this.thematicPagesPagination)
        .then((pagination) => {
          this.thematicPageInfiniteScrollId += 1
          this.thematicPagesPagination.lastPage = pagination.lastPage
          this.thematicPagesPagination.page = pagination.actualPage

          if (pagination.data) {
            if (!isLoadMore) {
              this.thematicPages = []
            }

            pagination.data.forEach(element => {
              this.thematicPages.push({
                name: element.name,
                id: element.id,
                thematicPageLearningPaths: element.thematicPageLearningPaths,
                thematicPageSolutions: element.thematicPageSolutions
              })
            })
          }

          return pagination
        })
    },

    isValidVimeoUrl (urlVimeo) {
      if (!urlVimeo) {
        return
      }

      const url = this.formatVimeoUrl(urlVimeo)

      this.attemptGetVimeoDetails(url)
        .then(() => {
          this.validPodcastUrl = true
        })
        .catch(() => {
          this.validPodcastUrl = false
        })
    },

    formatVimeoUrl (url) {
      if (url.includes('sebraesp/review')) {
        const newUrl = url.split('/')

        return 'https://vimeo.com/' + newUrl[5]
      }

      return url
    },

    removePodcastFile () {
      this.formData.podcastFile = null
    },

    setup () {
      this.TRANSLATION = TRANSLATION
      this.RICH_TEXT_EDITOR_TOOLBAR = RICH_TEXT_EDITOR_TOOLBAR
    },

    getSolutionTypeIntegrations () {
      this.setFetching(true)
      this.integrationsList = []

      this.attemptGetSolutionTypeIntegration(this.formData.solutionType)
        .then((response) => {
          if (response.data) {
            response.data.data.forEach(element => {
              this.integrationsList.push({
                text: element.integration.name,
                alias: element.integration.alias.toLowerCase(),
                value: element.id
              })

              if (element.integration.alias.toLowerCase() === 'local' && response.data.data.length === 1) {
                this.showIntegrations = false
                this.formData.integration = element.id
              } else {
                this.showIntegrations = true
              }
            })
          }

          this.setFetching(false)
        })
        .catch(() => {
          this.setFetching(false)
        })
    },

    setMinimumSolutionPrice () {
      const filter = this.configurationList.filter(item => item.text === 'financial_credit_card_minimum_installment_value')

      this.minimumSolutionPrice = filter.length ? parseFloat(filter[0].value) : 0.0
    },

    validatePortfolio: debounce(async function () {
      if (this.formData.portfolioId) {
        this.setFetching(true)

        await this.attemptValidatePortfolio(this.formData.portfolioId)
          .then((response) => {
            if (response.data) {
              this.isValidPortfolioId = true
            } else {
              this.isValidPortfolioId = false
            }
          })
          .catch(() => {
            this.isValidPortfolioId = false
          })
          .finally(() => {
            this.$v.$touch()
            this.setFetching(false)
          })
      } else {
        this.isValidPortfolioId = true
      }
    }, 500),

    fetchConnections (groupSolution) {
      if (groupSolution.length > 0) {
        this.setFetching(true)

        Promise.all([
          this.getLearningPathsList(),
          this.getThematicPagesList()
        ])
          .finally(() => {
            this.setFetching(false)
          })
      }
    },

    addSolutionGroup (item) {
      if (!this.formData.solutionGroups) {
        this.formData.solutionGroups = []
      }

      if (item) {
        if (item.value === 'all') {
          this.formData.solutionGroups = this.groupList
        } else {
          this.formData.solutionGroups.push(item)
        }
      }
    },

    removeSolutionGroup (item) {
      if (item) {
        if (item.value === 'all') {
          this.formData.solutionGroups = []
        } else {
          this.formData.solutionGroups = this.formData.solutionGroups.filter(solutionGroup => solutionGroup.value !== item.value)
        }
      }
    }
  }
}
</script>

<template>
  <div class="main-content solutions-create">
    <ContentHeader
      :title="isEditing ? formData.name : $t('solutions.create:header.title')"
      dark-theme
    >
      <Action
        slot="back"
        type="button"
        :text="$t('global:back.solutions')"
        class="btn-back"
        icon="keyboard_backspace"
        @click="leave"
      />
      <ActionBar slot="actionbar" />
      <template slot="buttons">
        <Action
          :text="$t('global:form.save')"
          type="button"
          flat
          dark
          @click="submit(false)"
        />
      </template>
    </ContentHeader>
    <div
      v-show="activeTab === 1"
      class="main-content-inner"
    >
      <div class="center">
        <div class="solutions-create-header">
          <h2 class="solutions-create-title">
            {{ $t('global:form.step', { 'num': 1 }) }}
          </h2>
          <p
            class="solutions-create-description"
            v-html="$t('solutions.create:header.description')"
          />
          <span
            v-if="formData.active"
            class="td-tag active-ribbon"
          >{{ $t('global.status:active') }}</span>
          <span
            v-else
            class="td-tag inactive-ribbon"
          >{{ $t('global.status:inactive') }}</span>
        </div>
        <form
          class="form"
          @submit.prevent="submit"
        >
          <p
            v-show="!formData.serviceIntegration.status"
            class="warning-service-integration"
            :validation="$v.formData.serviceIntegration.status"
          >
            A conexão com essa integração expirou. Para reconectar, <a
              class="cursor-pointer"
              @click="connectServiceIntegration"
            >clique aqui</a>
          </p>
          <SelectField
            v-model="formData.solutionType"
            tabindex="0"
            :label="$t('global:form.solutions.type')"
            :items="solutionTypeList"
            :validation="$v.formData.solutionType"
            :disabled="isEditing"
          />
          <SelectField
            v-show="formData.solutionType && showIntegrations"
            v-model="formData.integration"
            :label="$t('global:form.type.integration')"
            :items="integrationsList"
            :validation="$v.formData.integration"
            :integration="true"
            :disabled="isEditing"
          />

          <InputField
            v-show="showIntegrationCode()"
            v-model="formData.integrationCourseId"
            :under-description="$t(selectedSolutionTypeText)"
            :label="$t('global:form.solutions.integration.id')"
            :validation="$v.formData.integrationCourseId"
            :disabled="isEditing"
          />

          <InputField
            v-show="integrationAlias === 'weni'"
            v-model="formData.conclusionGroup"
            :under-description="$t('solution.manage:field.description.conclusion.group')"
            :label="$t('global:form.solutions.conclusion.group')"
            :validation="$v.formData.conclusionGroup"
            :disabled="isEditing"
          />
          <InputField
            v-show="formData.solutionType === 'dicas_whatsapp'"
            v-model="formData.triggerWords"
            :under-description="$t('solution.manage:field.description.keyword')"
            :label="$t('global:form.solutions.trigger.words')"
            :validation="$v.formData.triggerWords"
            :disabled="isEditing"
          />

          <FormSection
            v-if="formData.solutionType === 'e-book'"
            :title="$t('global:form.solution.ebook.attachment')"
          >
            <FileField
              v-model="formData.file"
              :under-description="$t('solution.manage:field.description.attachment')"
              :label="$t('global:form.attach.file')"
              accept=".pdf"
              :validation="$v.formData.file"
            />
            <Action
              v-if="isEditing"
              class="view-file"
              icon="visibility"
              type="button"
              :text="$t('management.solutions.view.file')"
              primary
              @click="viewEbookSolutionAsset"
            />
          </FormSection>
          <InputField
            v-if="isVideo || isWebinar"
            v-model="formData.video"
            :under-description="integrationAlias === 'vimeo_youtube'
              ? $t('solution.manage:field.description.video') : integrationAlias === 'youtube_live' ?
                $t('solution.manage:field.description.youtube.live') : $t('solution.manage:field.description.webinar')"
            :label="$t('global:video.url')"
            :validation="$v.formData.video"
          />

          <FormSection
            v-show="integrationAlias === 'youtube_live'"
            :title="$t('global:form.has.chat.live')"
          >
            <Radio
              v-model="formData.liveChat"
              :under-description="$t('solution.manage:field.description.has.livechat')"
              :items="yesNoOptions"
              horizontal
              :validation="$v.formData.liveChat"
            />
          </FormSection>

          <FormSection
            v-if="isPodcast"
            :title="$t('global:form.solution.podcast.attachment')"
          >
            <FileField
              v-model="formData.podcastFile"
              :under-description="$t('solution.manage:field.description.podcast.file')"
              :label="$t('global:form.attach.file')"
              accept=".mp4"
              :validation="$v.formData.podcastFile"
              is-enabled-delete-button
              @clear="removePodcastFile()"
            />
          </FormSection>

          <InputField
            v-if="isPodcast"
            v-model="formData.podcast"
            :label="$t('global:podcast.url')"
            :validation="$v.formData.podcast"
            @blur="isValidVimeoUrl(formData.podcast)"
          />

          <InputField
            v-if="isExternalActivity"
            v-model="formData.externalLink"
            :label="$t('global:externalActivity.url')"
            :validation="$v.formData.externalLink"
          />

          <p
            v-if="isPodcast && ['ready', 'in_progress', 'failed'].includes(uploadStatus)"
            class="warning-service-integration"
          >
            <span v-if="['ready', 'in_progress'].includes(uploadStatus)">
              {{ $t("management.solutions:error.upload.in_progress") }}
            </span>
            <span v-if="['failed'].includes(uploadStatus)">{{ $t("management.solutions:error.upload.failed") }}</span>
          </p>

          <MediaPreview
            v-if="showMediaPreview"
            :media-url="getMediaPreviewUrl()"
          />

          <InputField
            v-model="formData.name"
            :label="$t('global:form.solutions.title')"
            :validation="$v.formData.name"
            :counter="100"
          />

          <InputField
            v-model="formData.slug"
            :label="$t('global:form.solutions.slug')"
            :under-description="formatedUrl"
            :validation="$v.formData.slug"
            :counter="100"
          />

          <FormSection :title="$t('solution.manage:field.description.restricted.access')">
            <Radio
              v-model="formData.restrictedAccess"
              :under-description="$t('solution.manage:field.under.description.restricted.access')"
              :items="restrictedAccessOptions"
              horizontal
              :disabled="isEditing"
              :validation="$v.formData.restrictedAccess"
            />

            <AppMultiselect
              v-if="formData.restrictedAccess"
              v-model="formData.solutionGroups"
              :options="groupList"
              :placeholder="$t('global:form.groups.select')"
              :validation="$v.formData.solutionGroups"
              @onSelect="addSolutionGroup"
              @onRemove="removeSolutionGroup"
            />
          </FormSection>

          <InputField
            v-model="formData.callText"
            :under-description="$t('solution.manage:field.description.calltext')"
            :label="$t('global:form.solutions.callText')"
            :validation="$v.formData.callText"
            :counter="250"
          />

          <FormSection :title="TRANSLATION.description.description">
            <RichTextEditor
              v-model="formData.description"
              :validation="$v.formData.description"
              :toolbar="RICH_TEXT_EDITOR_TOOLBAR"
              class="rich-text"
            />
          </FormSection>

          <FormSection :title="TRANSLATION.description.audience">
            <RichTextEditor
              v-model="formData.audience"
              :toolbar="RICH_TEXT_EDITOR_TOOLBAR"
              :validation="$v.formData.audience"
              class="rich-text"
            />
          </FormSection>

          <FormSection :title="TRANSLATION.description.differentials">
            <RichTextEditor
              v-model="formData.differentials"
              :validation="$v.formData.differentials"
              :toolbar="RICH_TEXT_EDITOR_TOOLBAR"
              class="rich-text"
            />
          </FormSection>

          <FormSection :title="$t('global:upload.add.image.spotlight')">
            <Upload
              v-model="formData.image"
              icon="image-multiple"
              :label="$t('global:upload.add.image')"
              :description="$t('global:upload.add.image.spotlight.description')"
              cropper
              :width="2160"
              :height="675"
              :preview="0.22"
              :validation="$v.formData.image"
            />
          </FormSection>

          <FormSection :title="$t('global:upload.add.image.card')">
            <Upload
              v-model="formData.cardImage"
              icon="image-multiple"
              :label="$t('global:upload.add.image')"
              :description="$t('global:upload.add.image.card.description')"
              cropper
              :width="385"
              :height="160"
              :preview="0.93"
              :validation="$v.formData.cardImage"
            />
          </FormSection>

          <div
            class="form-group clearfix"
            data-gap="40"
            data-cols="2"
          >
            <InputField
              v-model="formData.workload.number"
              type="number"
              :min="1"
              :label="$t('global:solution.workload')"
              :validation="$v.formData.workload.number"
              @mousewheel="blur($event)"
            />
            <SelectField
              v-model="formData.workload.type"
              :label="$t('global:form.type')"
              :items="timeDurationList"
              :validation="$v.formData.workload.type"
            />
          </div>

          <FormSection
            v-if="isEcommerceEnabled || (!isEcommerceEnabled && isSolutionPaid)"
            :title="$t('global:form.solution.investment')"
          >
            <Radio
              v-model="formData.paymentType"
              :under-description="$t('solution.manage:field.description.investment')"
              :items="paymentTypeOptions"
              horizontal
              :validation="$v.formData.paymentType"
              :disabled="isPresencial || !isEcommerceEnabled"
            />
          </FormSection>

          <FormSection
            v-show="isSolutionPaid"
            :title="$t('global:form.solution.price')"
          >
            <MoneyField
              v-model="formData.price"
              :label="$t('global:form.price')"
              prefix="R$ "
              :disabled="!isEcommerceEnabled"
              :validation="$v.formData.price"
            />
            <span
              v-if="minimumSolutionPriceErrorMessage"
              class="solution-price-error-message"
            >{{
              minimumSolutionPriceErrorMessage }}</span>
          </FormSection>

          <FormSection :title="$t('global:form.solution.showOnPortal')">
            <Radio
              v-model="formData.showOnPortalEnabled"
              :under-description="$t('solution.manage:field.description.show.portal')"
              :items="yesNoOptions"
              horizontal
              :disabled="!isEcommerceEnabled && isSolutionPaid"
              :validation="$v.formData.showOnPortalEnabled"
            />
          </FormSection>

          <!--
 <FormSection :title="$t('global:form.solution.exclusiveToCompany')">
            <Radio
              v-model="formData.exclusiveToCompanyEnabled"
              :under-description="$t('solution.manage:field.description.exclusiveToCompany')"
              :items="yesNoOptions"
              horizontal
              :validation="$v.formData.exclusiveToCompanyEnabled"
            />
          </FormSection>
-->

          <FormSection :title="$t('global:form.has.certificate')">
            <Radio
              v-model="formData.certificateEnabled"
              :under-description="$t('solution.manage:field.description.has.certificate')"
              :disabled="isEbook || isPodcast || isExternalActivity || isPresencial"
              :items="yesNoOptions"
              horizontal
              :validation="$v.formData.certificateEnabled"
            />
            <SelectField
              v-if="formData.certificateEnabled"
              v-model="formData.certificateId"
              :label="$t('global:form.certificate.model')"
              :items="certificateList"
              :validation="$v.formData.certificateId"
            />
          </FormSection>

          <FormSection :title="$t('global:form.has.subscription.survey')">
            <Radio
              v-model="formData.subscriptionSurveyEnabled"
              :under-description="$t('solution.manage:field.description.has.subscription.survey')"
              :items="yesNoOptions"
              horizontal
              :validation="$v.formData.subscriptionSurveyEnabled"
            />
            <SelectField
              v-if="formData.subscriptionSurveyEnabled"
              v-model="formData.subscriptionSurveyId"
              :label="$t('global:form.survey.type')"
              :items="subscriptionSurveyList"
              :validation="$v.formData.subscriptionSurveyId"
            />
            <Radio
              v-if="formData.subscriptionSurveyEnabled"
              v-model="formData.surveyRequired"
              :under-description="$t('solution.manage:field.description.subscription.survey.required')"
              :items="yesNoOptions"
              horizontal
              :validation="$v.formData.surveyRequired"
            />
          </FormSection>

          <FormSection :title="$t('global:form.has.survey')">
            <Radio
              v-model="formData.surveyEnabled"
              :under-description="$t('solution.manage:field.description.has.survey')"
              :items="yesNoOptions"
              horizontal
              :validation="$v.formData.surveyEnabled"
              :disabled="isPresencial"
            />
            <SelectField
              v-if="formData.surveyEnabled"
              v-model="formData.surveyId"
              :label="$t('global:form.survey.type')"
              :items="satisfactionSurveyList"
              :validation="$v.formData.surveyId"
            />
          </FormSection>

          <FormSection
            v-if="formData.certificateEnabled && formData.surveyEnabled"
            :title="$t('solution.manage:field.description.survey.required.certificate')"
          >
            <Radio
              v-model="formData.surveyRequiredForCertificate"
              :under-description="$t('solution.manage:field.description.survey.required.certificate.under-description')"
              :items="yesNoOptions"
              horizontal
            />
          </FormSection>

          <FormSection
            v-if="isClosedSessionEnabled"
            :title="$t('global:form.class.type')"
          >
            <Radio
              v-model="formData.classType"
              :under-description="$t(selectedSessionTypeText)"
              :items="classTypeOptions"
              :disabled="shouldDisableClassType"
              horizontal
              :validation="$v.formData.classType"
            />
          </FormSection>

          <FormSection
            v-if="formData.classType === 'open'
              && !(isEbook || isVideo || isWebinar || isPodcast || isExternalActivity)"
            :title="$t('global:form.class.deadlines')"
          >
            <div class="solution-deadline">
              <InputField
                v-model="formData.conclusionDeadline"
                :under-description="$t('solution.manage:field.description.time.to.finish')"
                class="col-8"
                type="number"
                :min="1"
                :label="$t('global:form.solutions.conclusion.deadline')"
                :validation="$v.formData.conclusionDeadline"
                hide-arrows
                @mousewheel="blur($event)"
              />

              <p class="col-4">
                {{ $t('global:days') }}
              </p>
            </div>

            <div class="solution-deadline">
              <InputField
                v-model="formData.reenrollmentDeadline"
                :under-description="$t('solution.manage:field.description.time.to.reenroll')"
                class="col-8"
                type="number"
                :min="0"
                :max="2147483648"
                :label="$t('global:form.solutions.reenrollment.deadline')"
                :validation="$v.formData.reenrollmentDeadline"
                hide-arrows
                @mousewheel="blur($event)"
              />

              <p class="col-4">
                {{ $t('global:days') }}
              </p>
            </div>
          </FormSection>

          <FormSection
            v-if="formData.classType === 'open'"
            :title="$t('global:form.enrollment.type')"
          >
            <Radio
              v-model="formData.enrollmentType"
              :under-description="$t('solution.manage:field.description.enrollment.type')"
              :items="enrollmentTypeOptions"
              horizontal
              :validation="$v.formData.enrollmentType"
              :disabled="shouldDisableEnrollmentType"
            />
          </FormSection>

          <!--
          <form-section :title="$t('global:upload.tease.video')">
            <input-field
              :label="$t('global:video.url')"
              v-model="formData.teaserLink"
              :validation="$v.formData.teaserLink"
            />
          </form-section>
          -->
        </form>
      </div>
    </div>
    <div
      v-show="activeTab === 2"
      class="main-content-inner"
    >
      <div class="center">
        <div class="solutions-create-header">
          <h2 class="solutions-create-title">
            {{ $t('global:form.step', { 'num': 2 }) }}
          </h2>
          <p
            class="solutions-create-description"
            v-html="$t('solutions.create:header.description.2')"
          />
          <span
            v-if="formData.active"
            class="td-tag active-ribbon"
          >{{ $t('global.status:active') }}</span>
          <span
            v-else
            class="td-tag inactive-ribbon"
          >{{ $t('global.status:inactive') }}</span>
        </div>
        <form
          class="form"
          @submit.prevent="submit"
        >
          <FormSection :title="$t('global:form.themes')">
            <SelectField
              v-model="formData.solutionThemes"
              :label="$t('global:form.theme')"
              multiple
              :items="themeList"
            />
            <!--
            <action :text="$t('global:form.solutions.add.theme')" type="button" flat></action>
-->
          </FormSection>

          <FormSection
            v-if="isActiveTargetAudienceModule"
            :title="$t('global:form.audiences')"
          >
            <SelectField
              v-model="formData.targetAudiences"
              :label="$t('global:form.audience')"
              multiple
              :items="targetAudienceList"
            />

            <!--
 <Action
              :text="$t('global:form.solutions.add.profile')"
              type="button"
              flat
            />
-->
          </FormSection>

          <FormSection :title="$t('global:form.solutions.tags')">
            <TextareaField
              v-model="formData.tags"
              :label="$t('global:form.solutions.tags')"
              :validation="$v.formData.tags"
              auto-grow
            />
          </FormSection>
          <FormSection>
            <TextareaField
              v-model="content"
              :label="$t('global:form.solutions.content')"
              :under-description="$t('solution.manage:field.description.content')"
              auto-grow
              max-rows="10"
              @keyup.enter.native="addToContent"
            />
            <ul>
              <li
                v-for="(item, index) in formData.content"
                :key="index"
                class="pragmatic-list"
              >
                <div class="pragmatic-tools">
                  <Action
                    icon="delete"
                    icon-right
                    flat
                    type="button"
                    @click="removeFromContent(index)"
                  />
                </div>
                {{ item }}
              </li>
            </ul>
          </FormSection>
        </form>
        <div class="link-selection">
          <FormSection :title="$t('global:form.solution.recommendation.type')">
            <Radio
              v-model="formData.recommendationType"
              :under-description="$t(selectedRecommendationTypeText)"
              :items="availableTargetRecommendations"
              horizontal
            />
          </FormSection>
          <template v-if="formData.recommendationType === 'solutions'">
            <div class="search-field">
              <InputField
                v-model="solutionPagination.query.name"
                :label="$t('solution.manage:search.solutions')"
              />
            </div>
            <div class="link-table">
              <div class="solutions-available">
                <Datatable
                  :items="availableSolutions"
                  hide-horizontal-line
                >
                  <template slot="thead">
                    <th
                      class="th"
                      width="300"
                    >
                      {{ $t('solution.recommendations.list:datatable.header.1') }}
                    </th>
                    <th
                      class="th"
                      width="40"
                    />
                  </template>
                  <template
                    slot="tbody"
                    slot-scope="props"
                  >
                    <tr>
                      <td class="td td-full">
                        <span class="td-text bolder">{{ props.item.name }}</span>
                      </td>
                      <td class="td td-button td-small text-right">
                        <Action
                          type="button"
                          icon="keyboard_arrow_right"
                          flat
                          @click="addToRecommededSolutions(props.item.id)"
                        />
                      </td>
                    </tr>
                  </template>
                </Datatable>
                <Pagination
                  :scroll-enabled="false"
                  :active-page="solutionPagination.page"
                  :page-count="solutionPagination.lastPage"
                  @firstPage="paginationFirstPage(solutionPagination)"
                  @lastPage="paginationLastPage(solutionPagination)"
                  @nextPage="paginationNextPage(solutionPagination)"
                  @previousPage="paginationPrevPage(solutionPagination)"
                />
              </div>
              <Divisor orientation="vertical" />
              <div class="recommended-solutions">
                <Datatable
                  :items="formData.recommendedSolutions"
                  hide-horizontal-line
                >
                  <template slot="thead">
                    <th class="th">
                      {{ $t('solution.recommendations.list:datatable.header.2') }}
                    </th>
                    <th
                      class="th"
                      width="40"
                    />
                  </template>
                  <template
                    slot="tbody"
                    slot-scope="props"
                  >
                    <tr>
                      <td class="td td-full">
                        <span class="td-text bolder">{{ props.item.name }}</span>
                      </td>
                      <td class="td td-button td-small text-right">
                        <Action
                          type="button"
                          icon="close"
                          flat
                          @click="removeRecommendedSolution(props.item.id)"
                        />
                      </td>
                    </tr>
                  </template>
                </Datatable>
              </div>
            </div>
          </template>
        </div>
      </div>
    </div>
    <div
      v-show="activeTab === 3"
      class="main-content-inner"
    >
      <div class="center">
        <div class="solutions-create-header">
          <h2 class="solutions-create-title">
            {{ $t('global:form.step', { 'num': 3 }) }}
          </h2>
          <p
            class="solutions-create-description"
            v-html="$t('solutions.create:header.description.3')"
          />
          <span class="under-description">{{ $t('solutions.create:header.description.3.under-description') }}</span>
          <div>
            <span
              v-if="formData.active"
              class="td-tag active-ribbon"
            >{{ $t('global.status:active') }}</span>
            <span
              v-else
              class="td-tag inactive-ribbon"
            >{{ $t('global.status:inactive') }}</span>
          </div>
        </div>
        <form
          class="form"
          @submit.prevent="submit"
        >
          <InputField
            v-model="formData.seo.title"
            :label="$t('global:form.solutions.seo.title')"
          />
          <InputField
            v-model="formData.seo.description"
            :label="$t('global:form.solutions.seo.description')"
          />
          <InputField
            v-model="formData.seo.keywords"
            :label="$t('global:form.solutions.seo.keywords')"
            :hint="$t('global:form.solutions.seo.keywords.hint')"
          />
        </form>
      </div>
    </div>
    <div
      v-show="activeTab === 4"
      key="Tab de vínculos"
      class="main-content-inner"
    >
      <div class="center">
        <div class="solutions-create-header">
          <h2 class="solutions-create-title">
            {{ $t('global:form.step', { 'num': 4 }) }}
          </h2>
          <p
            class="solutions-create-description"
            v-html="$t('solutions.create:header.description.4')"
          />
          <span
            v-if="formData.active"
            class="td-tag active-ribbon"
          >{{ $t('global.status:active') }}</span>
          <span
            v-else
            class="td-tag inactive-ribbon"
          >{{ $t('global.status:inactive') }}</span>
        </div>
        <form
          class="center"
          @submit.prevent="submit"
        >
          <Tabs
            slot="tabs"
            :links="linkToSolutionTab"
            :index-active="linkToSolutionTabActive"
            @tabChange="linkToSolutionTabActive = $event"
          />

          <div
            v-show="linkToSolutionTabActive === 0"
            class="link-selection"
          >
            <FormSection
              :title="$t('show.in.Paths')"
              class="form-section--centered"
            >
              <Radio
                v-model="formData.showPaths"
                :items="yesNoOptions"
                horizontal
                @input="clearLinkedPaths"
              />
            </FormSection>

            <div
              v-if="formData.showPaths"
              class="link-table"
            >
              <div class="linkable-items linkable-paths">
                <Datatable
                  :is-loading-more="isLoadingMorePaths"
                  :items="availablePaths"
                  hide-horizontal-line
                  has-infinite-scroll
                  has-fade
                  empty-message="no_result"
                  @loadMore="loadMorePaths"
                >
                  <template slot="thead">
                    <th
                      class="th"
                      width="300"
                    >
                      {{ $t('paths.list:datatable.header.1') }}
                    </th>
                  </template>

                  <template slot="tsearch">
                    <FilterListSearch
                      :prepend-label="$t('global:form.track-search')"
                      :hide-error-details="true"
                      class="filter-search"
                      @search="searchPath"
                    />
                  </template>

                  <template
                    slot="tbody"
                    slot-scope="props"
                  >
                    <tr
                      :key="props.item.id"
                      @click="addToLinkedPath(props.item.id)"
                    >
                      <td class="td td-full">
                        <span class="td-text bolder">{{ props.item.name }}</span>
                        <br>
                        <span class="td-text td-small">
                          {{ props.item.learningPathSolutions }} {{ props.item.learningPathSolutions === 1
                            ? 'Solução'
                            : 'Soluções' }} | {{ props.item.workload ? props.item.workload : '0h' }}
                        </span>
                      </td>

                      <td class="td td-button td-small text-right">
                        <Action
                          type="button"
                          icon="keyboard_arrow_right"
                          flat
                        />
                      </td>
                    </tr>
                  </template>
                </Datatable>
              </div>
              <Divisor orientation="vertical" />
              <div class="linked-items">
                <Datatable
                  has-fade
                  :items="formData.linkedPaths"
                  hide-horizontal-line
                >
                  <template slot="thead">
                    <th class="th">
                      {{ $t('paths.list:datatable.header.2') }}
                    </th>
                    <th
                      class="th"
                      width="40"
                    />
                  </template>
                  <template
                    slot="tbody"
                    slot-scope="props"
                  >
                    <tr :key="props.item.id">
                      <td class="td td-full">
                        <span class="td-text bolder">{{ props.item.name }}</span>
                      </td>
                      <td class="td td-button td-small text-right">
                        <Action
                          type="button"
                          icon="close"
                          flat
                          @click="removeLinkedPath(props.item.id)"
                        />
                      </td>
                    </tr>
                  </template>
                </Datatable>
              </div>
            </div>
          </div>
          <div
            v-show="linkToSolutionTabActive === 1"
            class="link-selection"
          >
            <FormSection
              :title="$t('show.in.Themes')"
              class="form-section--centered"
            >
              <Radio
                v-model="formData.showThematicPages"
                :items="yesNoOptions"
                horizontal
                @input="clearLinkedThematicPages"
              />
            </FormSection>

            <FormSection
              v-show="false"
              :title="$t('global:form.solution.exclusiveToThematicPages')"
              class="form-section--centered"
            >
              <Radio
                v-model="formData.exclusiveToThematicPagesEnabled"
                :items="yesNoOptions"
                horizontal
                :validation="$v.formData.exclusiveToThematicPagesEnabled"
              />
            </FormSection>

            <div
              v-if="formData.showThematicPages"
              class="link-table"
            >
              <div class="linkable-items">
                <Datatable
                  :is-loading-more="isLoadingMoreThematicPages"
                  :items="availableThematicPages"
                  hide-horizontal-line
                  has-infinite-scroll
                  has-fade
                  empty-message="no_result"
                  @loadMore="loadMoreThematicPages"
                >
                  <template slot="thead">
                    <th
                      class="th"
                      width="300"
                    >
                      {{ $t('themes.list:datatable.header.1') }}
                    </th>
                    <th
                      class="th"
                      width="40"
                    />
                  </template>

                  <template slot="tsearch">
                    <FilterListSearch
                      :prepend-label="$t('global:form.theme-search')"
                      :hide-error-details="true"
                      class="filter-search"
                      @search="searchThematicPage"
                    />
                  </template>

                  <template
                    slot="tbody"
                    slot-scope="props"
                  >
                    <tr
                      :key="props.item.id"
                      @click="addToLinkedThematicPage(props.item.id)"
                    >
                      <td class="td td-full">
                        <span class="td-text bolder">{{ props.item.name }}</span>
                        <br>
                        <span class="td-text td-small">{{ props.item.thematicPageLearningPaths.length }} {{
                          props.item.thematicPageLearningPaths.length === 1 ? 'Trilha' : 'Trilhas' }} | {{
                          props.item.thematicPageSolutions.length }} {{ props.item.thematicPageSolutions.length === 1 ?
                          'Solução' : 'Soluções' }}</span>
                      </td>

                      <td class="td td-button td-small text-right">
                        <Action
                          type="button"
                          icon="keyboard_arrow_right"
                          flat
                        />
                      </td>
                    </tr>
                  </template>
                </Datatable>
              </div>
              <Divisor orientation="vertical" />
              <div class="linked-items">
                <Datatable
                  has-fade
                  :items="formData.linkedThematicPages"
                  hide-horizontal-line
                >
                  <template slot="thead">
                    <th class="th">
                      {{ $t('themes.list:datatable.header.2') }}
                    </th>
                    <th
                      class="th"
                      width="40"
                    />
                  </template>
                  <template
                    slot="tbody"
                    slot-scope="props"
                  >
                    <tr :key="props.item.id">
                      <td class="td td-full">
                        <span class="td-text bolder">{{ props.item.name }}</span>
                      </td>
                      <td class="td td-button td-small text-right">
                        <Action
                          type="button"
                          icon="close"
                          flat
                          @click="removeLinkedThematicPage(props.item.id)"
                        />
                      </td>
                    </tr>
                  </template>
                </Datatable>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
    <div
      v-show="activeTab === 5 && (isRaeEnabled || isSasEnabled)"
      class="main-content-inner"
    >
      <div class="center">
        <div class="solutions-create-header">
          <h2 class="solutions-create-title">
            {{ $t('global:form.step', { 'num': 5 }) }}
          </h2>
          <p
            class="solutions-create-description"
            v-html="$t('solutions.create:header.description.5')"
          />
          <span class="under-description">{{ $t('solutions.create:header.description.5.under-description') }}</span>
          <div>
            <span
              v-if="formData.active"
              class="td-tag active-ribbon"
            >{{ $t('global.status:active') }}</span>
            <span
              v-else
              class="td-tag inactive-ribbon"
            >{{ $t('global.status:inactive') }}</span>
          </div>
        </div>
        <form
          class="form"
          @submit.prevent="submit"
        >
          <InputField
            v-if="isRaeEnabled"
            v-model="formData.portfolioId"
            :under-description="$t('solution.manage:field.description.sebrae.code')"
            type="number"
            :min="1"
            :label="$t('global:form.solutions.portfolio.id')"
            :validation="$v.formData.portfolioId"
            hide-optional
            @keyup="validatePortfolio"
          />

          <FormSection
            v-if="isRaeEnabled"
            :title="$t('global:form.rae.sync_session')"
          >
            <span
              v-show="isSolutionOpen"
              class="warning-rae-configuration"
            >{{
              $t('solution.manage:field.description.rae.warning_configuration') }}</span>
            <span
              v-show="isPresencial"
              class="warning-rae-configuration"
            >{{
              $t('solution.manage:field.description.rae.warning_configuration.presencial.not_changeable') }}</span>
            <Radio
              v-model="formData.rae_sync_session"
              :under-description="$t('solution.manage:field.description.rae.sync_session')"
              :items="yesNoOptions"
              :disabled="shouldDisableRaeSyncSession"
              horizontal
              :validation="$v.formData.rae_sync_session"
            />
          </FormSection>

          <FormSection
            v-if="isRaeEnabled"
            :title="$t('global:form.rae.sync_enrollment')"
          >
            <span
              v-show="isSolutionOpen"
              class="warning-rae-configuration"
            >{{
              $t('solution.manage:field.description.rae.warning_configuration') }}</span>
            <span
              v-show="isPresencial"
              class="warning-rae-configuration"
            >{{
              $t('solution.manage:field.description.rae.warning_configuration.presencial.not_changeable') }}</span>
            <Radio
              v-model="formData.rae_sync_enrollment"
              :under-description="$t('solution.manage:field.description.rae.sync_enrollment')"
              :items="yesNoOptions"
              :disabled="shouldDisableRaeSyncEnrollment"
              horizontal
              :validation="$v.formData.rae_sync_enrollment"
            />
          </FormSection>

          <FormSection
            v-if="isRaeEnabled"
            :title="$t('global:form.rae.exclusive_enrollment')"
          >
            <span
              v-show="isSolutionOpen"
              class="warning-rae-configuration"
            >{{
              $t('solution.manage:field.description.rae.warning_configuration') }}</span>
            <span
              v-show="isPresencial"
              class="warning-rae-configuration"
            >{{
              $t('solution.manage:field.description.rae.warning_configuration.presencial.non_applicable') }}</span>
            <Radio
              v-model="formData.rae_exclusive_enrollment"
              :under-description="$t('solution.manage:field.description.rae.exclusive_enrollment')"
              :items="yesNoOptions"
              :disabled="shouldDisableRaeExclusiveEnrollment"
              horizontal
              :validation="$v.formData.rae_exclusive_enrollment"
            />
          </FormSection>

          <FormSection
            v-if="isSasEnabled"
            :title="$t('global:form.has.sas.event')"
          >
            <Radio
              v-model="formData.hasEvent"
              :under-description="$t('solution.manage:field.description.has.sas.event')"
              :items="yesNoOptions"
              horizontal
              :validation="$v.formData.hasEvent"
            />
            <span
              v-show="hasEvent && !formData.hasEvent"
              class="event-alert"
            >
              <Icon name="alert-circle" /> {{ $t('solution.manage:field.description.has.sas.event.disabled') }}
            </span>
          </FormSection>

          <FormSection v-show="formData.hasEvent">
            <InputField
              v-show="formData.hasEvent"
              v-model="formData.sasId"
              :under-description="$t('solution.manage:field.description.sas.code')"
              type="number"
              :min="1"
              :label="$t('global:form.solutions.sas.id')"
              :validation="$v.formData.sasId"
              @mousewheel="blur($event)"
            />

            <SelectField
              v-show="formData.hasEvent"
              v-model="formData.sasUnityId"
              :under-description="$t('solution.manage:field.description.sasUnity.code')"
              :label="$t('global:form.solutions.sasUnity.id')"
              :items="sasUnityList"
              :validation="$v.formData.sasUnityId"
            />

            <SelectField
              v-show="formData.hasEvent"
              v-model="formData.sasProjectId"
              :under-description="$t('solution.manage:field.description.sasProject.code')"
              :label="$t('global:form.solutions.sasProject.id')"
              :items="sasProjectList"
              :validation="$v.formData.sasProjectId"
            />

            <SelectField
              v-show="formData.hasEvent"
              v-model="formData.sasActionId"
              :under-description="$t('solution.manage:field.description.sasAction.code')"
              :label="$t('global:form.solutions.sasAction.id')"
              :items="sasActionList"
              :validation="$v.formData.sasActionId"
            />
          </FormSection>
        </form>
      </div>
    </div>
    <Pagination
      class="center"
      :active-first-last="false"
      :active-page="activeTab"
      :page-count="(isRaeEnabled || isSasEnabled) ? 5 : 4"
      @nextPage="nextPage"
      @previousPage="prevPage"
    />
  </div>
</template>

<style src="@/assets/styles/themes/default/solutions.css"></style>

<style lang="scss" src="../assets/scss/Solution.scss"></style>

<style>
.link-selection,
.link-selection .form-section {
  display: flex;
  margin: 40px auto 32px;
  flex-direction: column;
}

.link-selection .form-section+.form-section {
  margin-top: 0;
}

.link-selection .form-section {
  width: 480px;
}

.link-selection .form-section.form-section--centered {
  text-align: center;
}

.link-selection .search-field,
.link-selection .link-table>div {
  width: 50%;
}

.link-table {
  width: 100%;
  display: flex;
  flex-direction: row;
}

.solutions-create-header+.center .tabs {
  display: flex;
  justify-content: center;
  margin: 65px 0 80px;
}

.warning-service-integration {
  padding: 10px;
  background-color: #ffc14d;
  margin-bottom: 10px;
}

.warning-rae-configuration {
  color: #fa6666;
  font-size: 12px;
}

.pragmatic-list {
  display: flex;
  align-items: center;
  font-size: 14px;
}

.pragmatic-tools {
  padding-right: 15px;
}

.pragmatic-tools button {
  padding: 4px;
}

.td-tag.active-ribbon {
  background: #beffbe;
}

.td-tag.inactive-ribbon {
  background: #ffadad;
}

.td-tag {
  display: inline-block;
  vertical-align: middle;
  font-size: 1.2em;
  color: rgba(0, 0, 0, 0.5);
  line-height: 30px;
  padding: 0 10px;
  min-width: 70px;
  text-align: center;
  background: #F3F4F7;
  border-radius: 50px;
  text-transform: none;
}

.under-description {
  display: inline-block;
  font-size: 1.2em;
  color: rgba(0, 0, 0, 0.5);
  padding: 5px 0;
  max-width: 480px;
  text-transform: none;
}

.form-input-file-label-text,
.form-section-title {
  text-transform: none;
}

.btn.view-file {
  font-size: 16px;
  text-transform: none;
}

.solution-price-error-message {
  color: #ffc14d;
  font-size: 14px;
}
</style>
